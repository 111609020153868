import { AfterViewInit, ChangeDetectorRef, Component, Input, ViewChild } from "@angular/core";
import { SwiperComponent, SwiperModule } from "swiper/angular";
import { iconLib } from "@app/core/const/roam-icon";
import { SwiperOptions } from "swiper";
import { cardMetricConfig } from "@app/pages/maintenance/shared/const/card-metric-swiper.cons";
import { CardMetricComponent } from "../card-metric/card-metric.component";
import { CommonModule } from "@angular/common";
import { InlineSVGModule } from "ng-inline-svg-2";

@Component({
  standalone: true,
  selector: 'app-roam-swiper-metrics',
  templateUrl: './roam-swiper-metrics.component.html',
  imports: [
    CommonModule,
    SwiperModule,
    InlineSVGModule,
    CardMetricComponent
  ],
  styles: [
      `
      ::ng-deep.swiper {
        padding: 5px!important;
      }
    `
  ]
})
export class RoamSwiperMetricsComponent implements AfterViewInit{

  @Input()
  public metrics: any[] = [];

  @Input()
  public currencyType: boolean = true;

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  public icon = iconLib;
  public swiperConfig: SwiperOptions = cardMetricConfig;

  constructor(
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  get isSlideBeginning(): boolean {
    return this.swiper?.swiperRef.isBeginning || false;
  }

  get isSlideEnd(): boolean {
    return this.swiper?.swiperRef.isEnd || false;
  }

  slideNext(){
    this.swiper?.swiperRef.slideNext(100);
  }

  slidePrev(){
    this.swiper?.swiperRef.slidePrev(100);
  }
}
