import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { RouterModule } from '@angular/router';

@Component({
  standalone: true,
  imports: [CommonModule, MatCardModule, RouterModule],
  selector: 'app-card-metric',
  templateUrl: './card-metric.component.html',
  styleUrls: ['./card-metric.component.scss']
})
export class CardMetricComponent {

  @Input()
  public accountId!: string;

  @Input()
  public title!: string;

  @Input()
  public number!: number;

  @Input()
  public change!: number;

  @Input()
  public duration!: string;

  @Input()
  public currencyType: boolean = true;

  get direction(): string {
    return this.change > 0 ? '/assets/svg/ic-up.svg' : '/assets/svg/ic-down.svg'
  }

  get colorChange(): string {
    return this.change > 0 ? '#2ab51e' : '#d40101';
  }

}
