import { SwiperOptions } from "swiper";

export const cardMetricConfig: SwiperOptions = {
  slidesPerView: 4,
  spaceBetween: 20,
  breakpoints: {
    1370: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
    1250: {
      slidesPerView: 3,
      spaceBetween: 10
    },
    1000: {
      slidesPerView: 4,
    },
    768: {
      slidesPerView: 2
    },
  }
};
