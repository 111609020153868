<div class="position-relative">
  <swiper #swiper [config]="swiperConfig">
    <ng-template swiperSlide *ngFor="let metric of metrics" class="swiper-card">
      <div class="card-wrapper">
        <app-card-metric [title]="metric.name"
                         [accountId]="metric.id"
                         [number]="(metric.balance | currency) || metric.number"
                         [change]="metric.change"
                         [currencyType]="currencyType"
        ></app-card-metric>
      </div>
    </ng-template>
  </swiper>
  <button class="btn-swiper-nav prev" (click)="slidePrev()"
          [ngStyle]="{visibility: isSlideBeginning ? 'hidden' : 'visible'}"
          [disabled]="isSlideBeginning">
    <span [inlineSVG]="icon.arrowNext.src"></span>
  </button>
  <button class="btn-swiper-nav next" (click)="slideNext()"
          [ngStyle]="{visibility : isSlideEnd ? 'hidden' : 'visible'}"
          [disabled]="isSlideEnd"
  >
    <span [inlineSVG]="icon.arrowNext.src"></span>
  </button>
</div>
